<template>
    <div  class="bg_display_blue">
        <div id="bg" class="bg-antrian">
            <div id="main" class="flex">
            <div class="active flex" style="height: 15%;margin-top: -10px;margin-left: 0px;">
                <div class="process flex" style="width: 100%; margin-right: 0;">
                <div class="header bg-white flex"
                    style="height:100%; padding-left: 30px; padding-right: 30px; border-radius:0 !important;">
                    <div class="loket-split flex" style="justify-content: left; width:30%; flex-direction: initial;">
                    <div class="wrap_logo">
                        <img :src="assetLocal('img/logo-rsh-wide.png')" />
                    </div>
                    </div>
                    <div class="loket-split flex" style="width:60%;">
                    <div class="date_time_wrap">
                        <h3 class="text-primary" style="font-size:3vh; text-align:center;">LAYANAN ANTRIAN RS HARAPAN MAGELANG</h3>
                        <p class="text-primary" style="margin:0;   text-align:center; font-size:1.8vh; padding-top:10px;">Jl. Panembahan Senopati
                        No. 11, Jurangombo Utara,<br />Kec. Magelang Sel., Kota Magelang, Jawa Tengah 56123</p>
                    </div>
                    </div>
                    <div class="loket-split flex" style="width:30%;">
                    <div class="date_time_wrap">
                        <p class="text-primary">{{ dates || getDate }}</p>
                        <h3 class="text-primary">{{ times || getTime }}</h3>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            <!--
            <div class="content_post flex">
              <div class="wrap_queue_highlight" style="display:block;">
                <div class="wrap_preview_checkin" style="max-width: 70%;">
                  <div class="warning_ci mt-2" style="background-color:#04ac1f;">
                    <i class="icon-users"></i>
                    <span class="spinner spinner-border text-white d-none"></span>
                  </div>


                  <div class="wrap_box_info">
                    <div class="dokter_wrap_schedule">
                      <div class="form-row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label style="color: black;">Poliklinik<small class="txt_mandatory">*</small></label>
                            <div class="clearfix"></div>
                            <v-select placeholder="Pilih Poli" style="background: white;" @input="apiGet();dokter=null" v-model="poli" :options="mPoli" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label style="color: black;">Dokter<small class="txt_mandatory">*</small></label>
                            <div class="clearfix"></div>
                            <v-select placeholder="Pilih Dokter" style="background: white;" @input="apiGet()" v-model="dokter" :options="mDokter" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                          </div>
                        </div>

                      </div>
                    </div>
                    <h2>Sisa Kuota Dokter</h2>
                    <div class="info_quota">
                      <p>{{kuota}}</p>
                    </div>

                    <div class="text-center mt-2">
                    
                      <router-link :to="{name: 'DisplayAntrian'}"
                        class="btn btn-secondary btn-display text-uppercase font-weight-semibold mr-2">Kembali</router-link>
                        
                      <a href="javascript:;" @click="printContent"
                        class="btn btn-success btn-display text-uppercase font-weight-semibold ml-2"><i class="icon-printer" style="font-size:20px; margin-right:10px;" ></i> Cetak Antrian</a>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            -->
            <div class="content_post flex">
              <div class="wrap_queue_highlight" style="display:block;">
                <div class="wrap_preview_checkin" style="max-width: 60%;">
                  <div class="heading_schedule_wrap mt-3">
                    <p>Jadwal Dokter</p>
                  </div>
                  <div class="wrap_box_info">
                    <div class="dokter_wrap_schedule">
                      <div class="form-row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label style="color: black;">Poliklinik</label>
                            <div class="clearfix"></div>
                            <v-select placeholder="Pilih Poli" style="background: white;" @input="apiGet();dokter=null" v-model="poli" :options="mPoli" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label style="color: black;">Dokter</label>
                            <div class="clearfix"></div>
                            <v-select placeholder="Pilih Dokter" style="background: white;" @input="apiGet()" v-model="dokter" :options="mDokter" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="table-responsive text-left">
                            <table class="table mb-0">
                              <thead>
                                <tr class="table-info">
                                  <th colspan="3">{{getDateNow}}</th>
                                </tr>
                                <tr>
                                  <th> Jam Praktek</th>
                                  <th> Nama Dokter</th>
                                  <th> Sisa Kuota </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(v,k) in (dataJadwal||[])" :key="k">

                                  <td>
                                    <div class="bd-highlight" style="font-size:16px;">{{v.start_jadwal}} - {{v.end_jadwal}}</div>
                                  </td>
                                  <td>
                                    <div class="bd-highlight" style="font-size:16px;">{{v.bu_full_name}}</div>
                                  </td>
                                  <td>
                                    <div class="d-flex align-items-center flex-row bd-highlight">
                                      <div class="info_quota">
                                        <p>{{v.kuota}}</p>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr v-if="!(dataJadwal||[]).length">
                                  <td class="text-center" colspan="99">Tidak ada jadwal</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="text-center mt-2">
                    
                      <router-link :to="{name: 'DisplayAntrian'}"
                        class="btn btn-secondary btn-display text-uppercase font-weight-semibold mr-2">Kembali</router-link>
                        
                      <a href="javascript:;" @click="printContent"
                        class="btn btn-success btn-display text-uppercase font-weight-semibold ml-2"><i class="icon-printer" style="font-size:20px; margin-right:10px;" ></i> Cetak Antrian</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            </div>
        </div>

        <!--
        <b-modal v-model="openModalAntrian" :title="'Jadwal Dokter'" size="lg" ok-only ok-title="Tutup">
          <div class="modal-body p-0">
            <div class="row mb-2">
              <div class="col-md-6">
                <b-form-group class="mb-0">
                  <label for="">Pilih Poli</label>
                  <v-select placeholder="Pilih Poli" @input="apiGet();dokter=null" v-model="poli" :options="mPoli" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group class="mb-0">
                  <label for="">Pilih Dokter</label>
                  <v-select placeholder="Pilih Dokter" @input="apiGet()" v-model="dokter" :options="mDokter" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                </b-form-group>
              </div>

              <div class="col-md-12 mt-3">
                <div class="table-responsive">
                  <table class="table mb-0">
                    <thead>
                      <tr>
                        <th> Nama Dokter</th>
                        <th> Waktu Periksa</th>
                        <th> Kuota Hari Ini </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(v,k) in (dataJadwal||[])" :key="k">
                        <th>
                          <div class="d-flex align-items-center flex-row bd-highlight">
                            <div class="p-2 bd-highlight">
                              <div class="wrap_img_docter">
                                <img :src="uploader(v.bu_pic)">
                              </div>
                            </div>
                            <div class="bd-highlight" style="font-size:16px;">{{v.bu_full_name}}</div>
                          </div>
                        </th>
                        <td>
                          <div class="d-flex align-items-center flex-row bd-highlight">
                            <p class="mb-0" style="font-size:16px;">{{v.start_jadwal}} - {{v.end_jadwal}}</p>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex align-items-center flex-row bd-highlight">
                            <p class="mb-0" style="font-size:16px;">{{v.kuota}}</p>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="!(dataJadwal||[]).length">
                        <td class="text-center" colspan="99">Tidak ada jadwal</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
        -->

    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import $ from 'jquery'
import _ from 'lodash'
import Gen from '@/libs/Gen.js'


export default {
    extends: GlobalVue,
    data() {
        return {
            times : null,
            dates : null,
            noAntrian : null,
            openModalAntrian : false,
            poli: null,
            dokter: null,
            waktu: null,
            mPoli: [],
            mDokter: [],
            dataJadwal: [],
            kuota: 0
        }
    },
    mounted() {
        setInterval(()=>{
            this.getTimeF()
        },1000)

        setInterval(()=>{
            this.getDatesF()
        },1000)

        this.apiGet()
    },
    methods: {
        getTimeF(){
            this.times = moment().format('HH:mm:ss')
        },
        getDatesF(){
            this.dates = moment().format('dddd, Do MMMM YYYY')
        },
        printPage(){
            let newWindow = window.open('', '', 'left=0,top=0,width=302,height=350,toolbar=0,scrollbars=0,status=0,addressbar=0'),
            document = newWindow.document.open(),
            pageContent =
                '<!DOCTYPE html>' +
                '<html>' +
                '<head>' +
                '<meta charset="utf-8" />' +
                '<title>Inventory</title>' +
                '<style type="text/css">@page { size: auto;  margin: 0mm; }body {-webkit-print-color-adjust: exact; font-family: Arial; }</style>' +
                '</head>' +
                '<body>' +
                `<div style="position: relative;max-width: 100%;margin: 0px auto;">
                    <div style="height:100%;position: relative;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;color: #000;line-height: 1.5;font-size: 13px;">
                        <div>
                            <table border="0" style="border-collapse: collapse;width: 100%;">
                            <tbody><tr>
                                <td style="padding:0;">
                                <table style="border-collapse: collapse;width: 100%;">
                                    <tbody><tr>
                                    <td style="padding:8px 12px;">
                                        <div style="float: left;width: 20%;vertical-align: middle;box-sizing: border-box;padding-right: 8px;">
                                        <div class="logo-print"></div>
                                        <img src="/emr/img/logo-rsh-mark.png" style="width: 100%;" alt="">
                                        </div>

                                        <div style="text-transform: uppercase;float: left;width: 80%;vertical-align: middle;box-sizing: border-box;padding-left: 8px;text-align: left;">
                                        <h1 style="margin:0;font-size: 14px;line-height: 20px;margin-bottom: 2px;">RS Harapan
                                            Magelang</h1>

                                        <p style="margin:0;font-size: 10px;">Jl. P. Senopati No. 11 Magelang </p>
                                        
                                        </div>
                                        <div style="display: table-cell; clear: both;"></div>
                                    </td>
                                    </tr>
                                </tbody></table>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding:0;">
                                <table style="border-collapse: collapse;width: 100%;">
                                    <tbody>
                                    <tr>
                                        <td style="padding:12px 24px;" colspan="2">
                                        <p style="margin:  0; text-align:center; font-size:16px;">Nomor Antrian Anda: </p>
                                        <h3 style="font-weight: bold; text-transform: uppercase;letter-spacing: 6px;text-align: center;font-size: 60px;
                                            margin: 0px 0 10px 0; line-height:1;">
                                        ${this.noAntrian}</h3>
                                        <p style="margin: 8px 0; text-align:center; font-size:12px;">Silakan menunggu panggilan di area tunggu pendaftaran dan perhatikan nomor antrian Anda.
                                        </p>
                                        <p style="margin: 8px 0; text-align:center; font-size:10px; color:#000;">*) Jika nomor antrian terlewatkan, silakan mengambil nomor antrian baru.
                                        </p>
                                        <div style="font-size: 16px;line-height: 26px; margin-top:20px; border-top:1px solid #ccc;">
                                            <p style="margin: 8px 0; font-size:12px; text-align:center; color:#000; tect-align:center;">
                                            ${ this.dates || this.getDate } ,
                                            ${ this.times || this.getTime }
                                            </p>
                                            
                                        </div>
                                        </td>
                                    </tr>

                                    </tbody>
                                </table>
                                </td>
                            </tr>
                            </tbody></table>
                        </div>
                    </div>
                </div>` +
                '</body></html>'
            document.write(pageContent)
            document.close()
            newWindow.moveTo(0, 0)
            newWindow.resizeTo(screen.width, screen.height)
            setTimeout(function() {
                newWindow.print()
                newWindow.close()
            }, 250)
        },
        printContent(){
          this.$socket.emit('refresh_data', {to : 'Admisi'})
          this.$socket.emit('refresh_data_all',{})
          Gen.apiRestDisplay("/generateNoAntrianAdmisi?token="+this.tokenPostDisplay,{data: {}}, "POST")
          .then(res =>{
              this.noAntrian = res.data
              setTimeout(()=>{
                  this.printPage()
              },250)
          })
        },
        apiGet(){
            this.loadingOverlay = true
            Gen.apiRestDisplay(
                "/displayCetakAntian?poli="+this.poli+"&dokter="+this.dokter+"&waktu="+this.waktu
            ).then(res=>{
              _.forEach(res.data, (v,k)=>{
                  this.$set(this, k, v)
              })
              this.loadingOverlay = false
            })
        },

    },
    computed: {
        getTime(){
            return moment().format('HH:mm:ss')
        },
        getDate(){
            return moment().format('dddd, Do MMMM YYYY')
        },
        getDateNow(){
            return moment().format('DD MMMM YYYY')
        },
    },
    sockets: {
        connect() {
            this.isConnected = true
        },

        disconnect() {
            this.isConnected = false
        },

        messageChannel(data) {
            this.socketMessage = data
        },
    },
}

</script>
<style lang="scss" scoped>
@media print {
    body * {
        visibility: hidden;
        -webkit-print-color-adjust:exact;
    }
    @page {
        margin: 0;
        pading: 0;
        width: 100%;
    }
  .logo-print{
    width:291px;
    height:291px;
    display: list-item;
    list-style-image: url('/emr/img/logo-rsh-mark.png');
    list-style-position: inside;
   }
   img {
        -webkit-print-color-adjust: exact;
    }
}

@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXu61F3f.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXO61F3f.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXq61F3f.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXy61F3f.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXC61F3f.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnX661A.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXu61F3f.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXO61F3f.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXq61F3f.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXy61F3f.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXC61F3f.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnX661A.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXu61F3f.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXO61F3f.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXq61F3f.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXy61F3f.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXC61F3f.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnX661A.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXu61F3f.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXO61F3f.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXq61F3f.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXy61F3f.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXC61F3f.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnX661A.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXu61F3f.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXO61F3f.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXq61F3f.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXy61F3f.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXC61F3f.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnX661A.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nErXyi0A.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nMrXyi0A.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nFrXyi0A.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nDrXyi0A.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nPrXyi0A.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nBrXw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nErXyi0A.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nMrXyi0A.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nFrXyi0A.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nDrXyi0A.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nPrXyi0A.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nBrXw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nErXyi0A.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nMrXyi0A.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nFrXyi0A.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nDrXyi0A.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nPrXyi0A.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nBrXw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nErXyi0A.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nMrXyi0A.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nFrXyi0A.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nDrXyi0A.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nPrXyi0A.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nBrXw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nErXyi0A.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nMrXyi0A.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nFrXyi0A.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nDrXyi0A.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nPrXyi0A.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nBrXw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
